import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const container = css`
  width: 100%;
  box-sizing: border-box;
  margin: 30px auto;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 16px;
    }
  }
  @media (min-width: ${BREAK_POINTS.tableUp}) {
    margin-left: -25px;
    margin-right: -25px;
    width: auto;
  }

  @media (min-width: ${BREAK_POINTS.smallTablet}) {
    margin-left: -125px;
    margin-right: -125px;
  }
`

export const profileRow = css`
  gap: 20px;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  @media (min-width: ${BREAK_POINTS.tableUp}) {
    grid-template-columns: 1fr 1fr;

  }
  @media (min-width: ${BREAK_POINTS.smallTablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const logoutRow = css`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0;
`

export const profileCard = css`
  padding: 15px;
  min-width: 180px;
  cursor: pointer;

  &:hover {
    border-color: ${colors.primary};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .AvatarCard__title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .AvatarCard__description {
    font-size: 14px;
    margin: 0 0 5px 0;
  }
`

export const loadingCss = css`
  margin: 0 auto;
`

import { authenticatedService } from '@/interfaces/authenticatedService'
import { decodeErrors as errorTranslate } from '@/utils/error-decoder'
import { extractInnerData, extractData } from '@/utils/data-extractor'
import { formatDate } from '@bonitour/common-functions'
import { VacanciesError, VacancyModel, ConflictObject } from '@/types/domains/Vacancies'

function getConflictingPeriods (conflicts: Array<ConflictObject> | Record<string, any>) {
  if (Array.isArray(conflicts)) {
    return conflicts
  } else {
    return Object.entries(conflicts).map(period => period[1].conflicts[0])
  }
}

function decodeError (error: VacanciesError) {
  if (/conflited_date_range/.test(error.errors_msg)) {
    const conflictingPeriods = getConflictingPeriods(error.extra_data.conflicting_periods)
      .map(({ label, initial_date, final_date }) =>
        `${label} (${formatDate(initial_date)} - ${formatDate(final_date)})`)
      .reduce((acc, curr) => `${acc}, ${curr}`)

    return `Período conflitante com: ${conflictingPeriods}`
  }
  return 'Ocorreu um erro ao criar a vaga'
}

const vacancyService = authenticatedService('/v1/vacancies/')

const decodeErrors = (errors: Array<string>) => errorTranslate(errors, decodeError)

const get = (serviceId: string) => vacancyService.get({ service_id: serviceId }).then(extractInnerData)

const getById = (vacancyId: string) => vacancyService.get({}, vacancyId).then(extractInnerData)

const remove = (serviceId: string, vacancyId: string) =>
  vacancyService.remove({ service_id: serviceId }, vacancyId)

function create (vacancy: VacancyModel) {
  return vacancyService.post(vacancy)
    .then(extractData)
    .catch(({ data }: { data: any }) => decodeErrors(data))
}

function update (vacancy: VacancyModel, vacancyId: string) {
  return vacancyService.patch(vacancy, vacancyId)
    .then(extractData)
    .catch(({ data }: { data: any }) => decodeErrors(data))
}

vacancyService.setServiceMethods({ get, getById, create, update, decodeErrors, remove })

export const vacancyDomain = vacancyService.initialize

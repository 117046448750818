/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { flexRowCentered } from '../../assets/styles/layout'

const inlineFlex = css`
  display: inline-flex;
`

const IconWrapper = ({ customCss = [], ...rest }) => (
  <div {...rest} css={[flexRowCentered, inlineFlex, ...customCss]} />
)

const Icon = iconName => {
  const iconComponent = props => (
    <IconWrapper {...props}>
      <i className={`b-font-${iconName}`} />
    </IconWrapper>
  )
  iconComponent.displayName = `Icon-${iconName}`
  return iconComponent
}

export const AbsIcon = Icon('abs')
export const AccountIcon = Icon('account')
export const ActionCamIcon = Icon('action-cam')
export const AddTicketIcon = Icon('add-tickets')
export const AdultIcon = Icon('adult')
export const AirBagIcon = Icon('air-bag')
export const AirConditioningIcon = Icon('air-conditioning')
export const AirplaneIcon = Icon('airplane')
export const AlertsIcon = Icon('alerts')
export const AngleDownIcon = Icon('angle-down')
export const AngleLeftIcon = Icon('angle-left')
export const AngleRightIcon = Icon('angle-right')
export const AngleThickDownIcon = Icon('angle-thick-down')
export const AngleThickLeftIcon = Icon('angle-thick-left')
export const AngleThickRightIcon = Icon('angle-thick-right')
export const AngleThickUpIcon = Icon('angle-thick-up')
export const AngleUpIcon = Icon('angle-up')
export const AppleIcon = Icon('apple')
export const ArrowDownIcon = Icon('arrow-down')
export const ArrowLeftIcon = Icon('arrow-left')
export const ArrowRightIcon = Icon('arrow-right')
export const ArrowThickDownIcon = Icon('arrow-thick-down')
export const ArrowThickLeftIcon = Icon('arrow-thick-left')
export const ArrowThickRightIcon = Icon('arrow-thick-right')
export const ArrowThickUpIcon = Icon('arrow-thick-up')
export const ArrowUpIcon = Icon('arrow-up')
export const ArrowPercentageIcon = Icon('arrow-percentage')
export const AuditIcon = Icon('audit')
export const BabyCarriageIcon = Icon('baby-carriage')
export const BabyIcon = Icon('baby')
export const BabySeatIcon = Icon('baby-seat')
export const BanIcon = Icon('ban')
export const BankCardIcon = Icon('bank-card')
export const BarsIcon = Icon('bars')
export const BathtubIcon = Icon('bathtub')
export const BedIcon = Icon('bed')
export const BellIcon = Icon('bell')
export const BellThickIcon = Icon('bell-thick')
export const BikeIcon = Icon('bike')
export const BillIcon = Icon('bill')
export const BinocularsIcon = Icon('binoculars')
export const BroomIcon = Icon('broom')
export const BuildingIcon = Icon('building')
export const BusIcon = Icon('bus')
export const BusinessIcon = Icon('business')
export const CabinIcon = Icon('cabin')
export const CarIcon = Icon('car')
export const CashierIcon = Icon('cashier')
export const CashierListIcon = Icon('cashier-list')
export const CashierHandlingIcon = Icon('cashier-handling')
export const CalendarIcon = Icon('calendar')
export const CameraIcon = Icon('camera')
export const CashbackIcon = Icon('cashback')
export const CashIcon = Icon('cash')
export const ChangesIcon = Icon('changes')
export const ChartsIcon = Icon('charts')
export const CheckCircle = Icon('check-circle')
export const CheckIcon = Icon('check')
export const CheckThickIcon = Icon('check-thick')
export const ClickIcon = Icon('click')
export const ClockIcon = Icon('clock')
export const ClosedLockIcon = Icon('closed-lock')
export const CloseIcon = Icon('close')
export const ClipboardConfigIcon = Icon('clipboard-config')
export const CoffeeIcon = Icon('coffee')
export const CogIcon = Icon('cog')
export const CompanyIcon = Icon('company')
export const CompassIcon = Icon('compass')
export const CopyIcon = Icon('copy')
export const CrockIcon = Icon('crock')
export const DayUseIcon = Icon('day-use')
export const DifficultyIcon = Icon('difficulty')
export const DiscIcon = Icon('disc')
export const DisabledPersonIcon = Icon('disabled-person')
export const DivingSuitIcon = Icon('diving-suit')
export const DocumentIcon = Icon('document')
export const DotsMenuIcon = Icon('dots-menu')
export const DragIcon = Icon('drag')
export const DesktopConfigIcon = Icon('desktop-config')
export const EclipseIcon = Icon('eclipse')
export const EditInfoIcon = Icon('edit-info')
export const EllipsisIcon = Icon('ellipsis')
export const ExternalLinkIcon = Icon('external-link')
export const ExtinguisherIcon = Icon('extinguisher')
export const ExportIcon = Icon('export')
export const EyeIcon = Icon('eye')
export const EyeSlashIcon = Icon('eye-slash')
export const FacebookIcon = Icon('facebook')
export const FilterIcon = Icon('filter')
export const FileExportIcon = Icon('file-export')
export const FinancialTransactionIcon = Icon('financial-transaction')
export const FlagIcon = Icon('flag')
export const FlashlightIcon = Icon('flashlight')
export const FlipFlopsIcon = Icon('flip-flops')
export const FloaterIcon = Icon('floater')
export const ForwardIcon = Icon('forward')
export const FridgeIcon = Icon('fridge')
export const FuelPumpIcon = Icon('fuel-pump')
export const GlobalIcon = Icon('global-icon')
export const GoogleIcon = Icon('google')
export const GuideIcon = Icon('guide')
export const HammockIcon = Icon('hammock')
export const HamburguerMenuIcon = Icon('hamburguer-menu')
export const HandshakeIcon = Icon('handshake')
export const HangerIcon = Icon('hanger')
export const HeartIcon = Icon('heart')
export const HeartOutlineIcon = Icon('heart-outline')
export const HeightIcon = Icon('height')
export const HelpIcon = Icon('help')
export const HelmetIcon = Icon('helmet')
export const HomeIcon = Icon('home')
export const HorseIcon = Icon('horse')
export const HourglassIcon = Icon('hourglass')
export const HouseIcon = Icon('house')
export const ImageIcon = Icon('image')
export const InfoIcon = Icon('info')
export const InsectRepellentIcon = Icon('insect-repellent')
export const IntegrationIcon = Icon('integration')
export const KayakIcon = Icon('kayak')
export const KeyIcon = Icon('key')
export const KidIcon = Icon('kid')
export const LakeIcon = Icon('lake')
export const LifeJacketIcon = Icon('life-jacket')
export const LightClothingIcon = Icon('light-clothing')
export const LinkIcon = Icon('link')
export const LockerRoomIcon = Icon('locker-room')
export const LogoutIcon = Icon('logout')
export const LuggageStorageIcon = Icon('luggage-storage')
export const MailIcon = Icon('mail')
export const MapIcon = Icon('map')
export const MapPinIcon = Icon('map-pin')
export const MapinFilledIcon = Icon('map-pin-filled')
export const MarkersIcon = Icon('markers')
export const MedicalKitIcon = Icon('medical-kit')
export const MegaphoneIcon = Icon('megaphone')
export const MessageBallonIcon = Icon('message-ballon')
export const MinimumWeightIcon = Icon('minimum-weight')
export const MinusCircle = Icon('minus-circle')
export const MinusIcon = Icon('minus')
export const MountIcon = Icon('mount')
export const MountainIcon = Icon('mountain')
export const MultiVacancyIcon = Icon('multi-vacancy')
export const MusicIcon = Icon('music')
export const NightIcon = Icon('night')
export const PregnantIcon = Icon('pregnant')
export const OnlineShoppingIcon = Icon('online-shopping')
export const OpenLockIcon = Icon('open-lock')
export const OpenPadlockIcon = Icon('open-padlock')
export const OperationalIcon = Icon('operational')
export const ParkingIcon = Icon('parking')
export const PartnersIcon = Icon('partners')
export const PasteIcon = Icon('paste')
export const PathHorizontalIcon = Icon('path-horizontal')
export const PathVerticalIcon = Icon('path-vertical')
export const PdfIcon = Icon('pdf-icon')
export const PencilIcon = Icon('pencil')
export const PeopleIcon = Icon('people')
export const PersonalInfoIcon = Icon('personal-info')
export const PersonIcon = Icon('person')
export const PinIcon = Icon('pin')
export const PlaygroundIcon = Icon('playground')
export const PlusIcon = Icon('plus')
export const PlusCircleIcon = Icon('plus-circle')
export const PrinterIcon = Icon('printer')
export const PoolIcon = Icon('pool')
export const PreferencesIcon = Icon('preferences')
export const ProfessionalIcon = Icon('professional')
export const PurchaseIcon = Icon('purchase')
export const RedeemCodeIcon = Icon('redeem-code')
export const RedoIcon = Icon('redo')
export const RefoundIcon = Icon('refound')
export const RefreshIcon = Icon('refresh')
export const RepellentIcon = Icon('repellent')
export const ReportsIcon = Icon('reports')
export const RestAreaIcon = Icon('rest-area')
export const RestroomIcon = Icon('restroom')
export const ReturnMoney = Icon('return-money')
export const RockIcon = Icon('rock')
export const RoleIndicatorIcon = Icon('role-indicator')
export const RouteIcon = Icon('route')
export const RowingIcon = Icon('rowing')
export const RulerIcon = Icon('ruler')
export const SafeBoxIcon = Icon('safe-box')
export const SafeBuyIcon = Icon('safe-buy')
export const SatelliteIcon = Icon('satellite')
export const SaturnIcon = Icon('saturn')
export const SearchIcon = Icon('search')
export const ScheduleIcon = Icon('schedule')
export const SendIcon = Icon('send')
export const SecurityIcon = Icon('security')
export const SeniorIcon = Icon('senior')
export const ShareIcon = Icon('share')
export const ShieldedIcon = Icon('shielded')
export const ShoppingBagIcon = Icon('shopping-bag')
export const ShortsIcon = Icon('shorts')
export const ShowerIcon = Icon('shower')
export const SimpleLockOpenIcon = Icon('simple-lock-open')
export const SimpleLockClosedIcon = Icon('simple-lock-closed')
export const SlidersIcon = Icon('sliders')
export const SmokingAreaIcon = Icon('smoking-area')
export const SnorkelIcon = Icon('snorkel')
export const SnorkelFlotationIcon = Icon('snorkel-flotation')
export const SnowflakeIcon = Icon('snowflake')
export const SocksIcon = Icon('socks')
export const SortDownIcon = Icon('sort-down')
export const SortLeftIcon = Icon('sort-left')
export const SortRightIcon = Icon('sort-right')
export const SortUpIcon = Icon('sort-up')
export const SquareCheckIcon = Icon('square-check')
export const StarFilledIcon = Icon('star-filled')
export const StarIcon = Icon('star')
export const SteeringWheelIcon = Icon('steering-wheel')
export const StopwatchIcon = Icon('stopwatch')
export const StoreIcon = Icon('store')
export const SuitcaseIcon = Icon('suitcase')
export const SunIcon = Icon('sun')
export const SunriseIcon = Icon('sunrise')
export const SunscreenIcon = Icon('sunscreen')
export const SunsetIcon = Icon('sunset')
export const SnackIcon = Icon('snack')
export const SneakersIcon = Icon('sneakers')
export const SwimsuitIcon = Icon('swimsuit')
export const TaxiIcon = Icon('taxi')
export const TicketIcon = Icon('ticket')
export const TicketsIcon = Icon('tickets')
export const ToucanIcon = Icon('toucan')
export const TouristAttractionIcon = Icon('tourist-attraction')
export const TowelIcon = Icon('towel')
export const TrainingIcon = Icon('training')
export const TransferIcon = Icon('transfer')
export const TranslatorIcon = Icon('translator')
export const TrashIcon = Icon('trash')
export const TrouserIcon = Icon('trouser')
export const TvIcon = Icon('tv')
export const UploadIcon = Icon('upload')
export const UserIcon = Icon('user')
export const UsersIcon = Icon('users')
export const UtensilsIcon = Icon('utensils')
export const VehicleIcon = Icon('vehicle')
export const VentilationIcon = Icon('ventilation')
export const VolleyballIcon = Icon('volleyball')
export const VoucherIcon = Icon('voucher')
export const WalletIcon = Icon('wallet')
export const WarningIcon = Icon('warning')
export const WaterfallIcon = Icon('waterfall')
export const WeightIcon = Icon('weight')
export const WeightKgIcon = Icon('weight-kg')
export const WhatsappIcon = Icon('whatsapp')
export const WheelchairIcon = Icon('wheelchair')
export const WiFiIcon = Icon('wifi')
export const WaterShoesIcon = Icon('water-shoes')
export const ZiplineIcon = Icon('zipline')
export const CheckinIcon = Icon('check-in')

import { Requester, AxiosInterceptor } from '@/external/requester'
import { AxiosRequestConfig } from 'axios'

const buildRoute = (resource = '', urlSuffix = '') => urlSuffix ? `${resource}/${urlSuffix || ''}` : resource

export function authenticatedService (resource: string) {
  let serviceMethods: Record<string, any> = {}
  let requester: any

  function initialize (baseURL: string, interceptFunction?: (interceptor: AxiosInterceptor) => void) {
    requester = Requester(baseURL, interceptFunction)
    return serviceMethods
  }

  function setServiceMethods (methods: object) {
    serviceMethods = methods
  }

  function get (params?: Record<string, any>, urlSuffix?: string, config = {}) {
    return requester
      .get(buildRoute(resource, urlSuffix), {
        params: {
          ...params
        },
        ...config
      })
  }

  function remove (params?: Record<string, any>, urlSuffix?: string) {
    return requester
      .delete(buildRoute(resource, urlSuffix), {
        params: {
          ...params
        }
      })
  }

  function post (
    data: Record<string, any>, urlSuffix?: string, config?: AxiosRequestConfig
  ) {
    return requester.post(buildRoute(resource, urlSuffix), data, config)
  }

  function postFormData (data: Record<string, any>, urlSuffix?: string) {
    return requester.post(buildRoute(resource, urlSuffix), data, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
  }

  function patchFormData (data: Record<string, any>, urlSuffix: string) {
    return requester.patch(buildRoute(resource, urlSuffix), data, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
  }

  function putFormData (data: Record<string, any>, urlSuffix: string) {
    return requester.put(buildRoute(resource, urlSuffix), data, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
  }

  function patch (data: Record<string, any>, urlSuffix: string) {
    return requester.patch(buildRoute(resource, urlSuffix), data)
  }

  function put (data: Record<string, any>, urlSuffix: string) {
    return requester.put(buildRoute(resource, urlSuffix), data)
  }

  return { initialize, setServiceMethods, get, post, patch, put, remove, postFormData, putFormData, patchFormData }
}

import { ErrorMap, ErrorReport, ParserErrors } from '@/types/domains/Error'

const buildErrorMessages = (errorMapping: ErrorMap = {}) => (errors: ErrorReport = {}): string[] => {
  const { errors_msg: messages = [] } = errors
  return messages.map(message => {
    const method = errorMapping[message]
    if (!method) {
      return ''
    }
    return method(errors)
  }).filter(Boolean)
}

export const parseError = (errorMapping: ErrorMap = {}) => ({ data: errors = {} }: {data: ErrorReport }): Promise<ParserErrors> => {
  return Promise.reject(
    {
      parsedErrors: buildErrorMessages(errorMapping)(errors)
    }
  )
}

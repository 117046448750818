import { AuthParser } from 'Core/Parser/Auth'
import { orbCore } from '../Orb/Service'

import {
  AffiliateCreateModel,
  AffiliateSignupModel,
  AffiliateListModel,
  AffiliateSalesModel,
  AffiliateTicketsModel,
  AffiliateCompaniesModel,
  AffiliateCompanySalesModel,
  AffiliateUpdateModel,
  AffiliateUpdateCustomCommissionModel,
  AffiliateSalesItemsExport,
  AffiliateSalesItemsExportByCompany
} from './Model'
import { AffiliateParser } from './Parser'
import { BatchTypes } from 'Shared/constants/batchTypes'

const { affiliateDomain, affiliateInviteDomain, paymentBatchesStatusTotalsDomain } = orbCore

export const AffiliateService = {
  create (data) {
    const requestPayload = AffiliateCreateModel(data)
    return affiliateDomain
      .create(requestPayload)
      .then(({ data: affiliate = {} }) => AffiliateParser.instance(affiliate))
  },

  update (affiliateId, data) {
    const requestPayload = AffiliateUpdateModel(data)
    return affiliateDomain.update(affiliateId, requestPayload)
  },

  updateCustomCommission (affiliateId, data) {
    const requestPayload = AffiliateUpdateCustomCommissionModel(data)
    return affiliateDomain.updateCustomCommission(affiliateId, requestPayload)
  },

  list (data) {
    const requestPayload = AffiliateListModel(data)
    return affiliateDomain
      .list(requestPayload)
      .then(({ affiliates = [], meta }) => {
        return AffiliateParser.list(affiliates, meta, requestPayload.per_page)
      })
  },

  get (affiliateId, companyId = null) {
    return affiliateDomain
      .get(affiliateId, companyId)
      .then(({ affiliate = {} }) => {
        return AffiliateParser.get(affiliate)
      })
  },

  sales (affiliateId, data) {
    const requestPayload = AffiliateSalesModel(data)
    return affiliateDomain
      .sales(affiliateId, requestPayload)
      .then(({ affiliate_sales = [], meta }) => {
        return AffiliateParser.sales(affiliate_sales, meta, requestPayload.per_page)
      })
  },

  ticketsToBatch (affiliateCode, data) {
    const requestPayload = AffiliateTicketsModel(data)
    return affiliateDomain
      .ticketsToBatch(affiliateCode, requestPayload)
      .then(({ affiliate_tickets = [], meta }) => {
        return AffiliateParser.tickets(affiliate_tickets, meta, requestPayload.per_page)
      })
  },

  toggleActive (affiliateId) {
    return affiliateDomain
      .toggleActive(affiliateId)
      .then(({ data: affiliate = {} }) => AffiliateParser.instance(affiliate))
  },

  affiliateCompanies (data) {
    const requestPayload = AffiliateCompaniesModel(data)
    return affiliateDomain
      .affiliateCompanies(requestPayload)
      .then(({ affiliate_companies = [], meta }) => {
        return AffiliateParser.companies(affiliate_companies, meta, requestPayload.per_page)
      })
  },

  getSalesByCompany (affiliateId, data) {
    const requestPayload = AffiliateCompanySalesModel(data)
    return affiliateDomain
      .getSalesByCompany(affiliateId, requestPayload)
      .then(({ affiliate_sales: affiliateSales, meta }) => {
        return AffiliateParser.sales(affiliateSales, meta, requestPayload?.per_page)
      })
  },

  getTotalizersByCompany (affiliateId, data) {
    const requestPayload = AffiliateCompanySalesModel(data)
    return affiliateDomain
      .getTotalizersByCompany(affiliateId, requestPayload)
      .then(({ affiliate_totalizers: affiliateTotalizers = {} }) => AffiliateParser.totalizersInstance(affiliateTotalizers))
  },

  getItemsToExport (affiliateId, format, data) {
    const requestPayload = AffiliateSalesItemsExport(data)
    return affiliateDomain.getItemsToExport(affiliateId, format, requestPayload)
  },

  getItemsToExportByCompany (companyId, format, data) {
    const requestPayload = AffiliateSalesItemsExportByCompany(data)
    return affiliateDomain.getItemsToExportByCompany(companyId, format, requestPayload)
  },

  getBatchTotalsByAffiliate (affiliateId) {
    return paymentBatchesStatusTotalsDomain.getBeneficiaryTotals(affiliateId, BatchTypes.affiliate).then(AffiliateParser.batchTotals)
  }

}

export const AffiliateInviteService = {
  userInviteSignup (data) {
    const requestPayload = AffiliateSignupModel(data)
    return affiliateInviteDomain.userInviteSignup(requestPayload).then(AuthParser.payload)
  }
}

import axios, { AxiosResponse, AxiosRequestConfig, AxiosInterceptorManager } from 'axios'

export type AxiosInterceptor={
  request: AxiosInterceptorManager<AxiosRequestConfig>;
  response: AxiosInterceptorManager<AxiosResponse>;
};

function Axios (baseURL: string, interceptFunction?: (interceptor: AxiosInterceptor) => void) {
  const axiosInstance = axios.create({
    baseURL,
    withCredentials: false
  })

  axiosInstance.interceptors.response.use(
    response => response,
    ({ response }) => Promise.reject(response)
  )

  if (interceptFunction) {
    interceptFunction(axiosInstance.interceptors)
  }

  return {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
    delete: axiosInstance.delete
  }
}

export const Requester = Axios

/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fra */
import { jsx, css } from '@emotion/core'
import { Logo, H4, Card, colors, Column, Row, H2, CollapsableSection, Accordion } from '@bonitour/components'
import logoOrb from 'Shared/svgs/logoOrb.svg'
import logoPay from 'Shared/svgs/logoBinamikPay.svg'
import logoXpert from 'Shared/svgs/logoXpert.svg'
import logoBilling from 'Shared/svgs/logoBilling.svg'
import logoMoov from 'Shared/svgs/logoMoov.svg'
import { CardButton } from 'Shared/components/CardButton'
import { NavLink } from 'react-router-dom'
import { clickable, marginBottom, mobileCardContainer } from 'assets/styles/global'

const { REACT_APP_ORB_URL, REACT_APP_PAY_URL, REACT_APP_XPERT_URL, REACT_APP_BILLING_URL, REACT_APP_MOOV_URL } = process.env

const marginBottom30 = marginBottom(30)

const productsLabel = css`
  margin: 30px 0 10px 0;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.gray5};
`

const logoPadding = css`
  padding: 8px;
`

const marginVertical = css`
  margin-bottom: 30px;
  margin-top: 30px;
  width: 1000px;
`
const navLink = css`
  text-decoration: none;
`

const cardButton = [
  clickable,
  css`
    width: 134px;
    box-sizing: border-box;
    margin: 0;
  `
]

const redirectOnClick = (link) => () => {
  window.location = link
}

export const DashboardProducts = ({ hasCompany = false }) => {
  if (!hasCompany) {
    return (
      <Row>
        <Column phone={12}>
          <Card css={marginVertical}>
            <H2 css={marginBottom30}>Próximos passsos</H2>

            <NavLink to='/app/company/create' css={navLink}>
              <Accordion>
                <CollapsableSection indicator={1} title='Cadastre sua empresa' />
              </Accordion>
            </NavLink>
          </Card>
        </Column>
      </Row>
    )
  }

  return (
    <Row>
      <Column phone={12}>
        <H4 css={productsLabel}>Produtos</H4>
      </Column>

      <Column phone={12} css={mobileCardContainer}>
        <CardButton onClick={redirectOnClick(REACT_APP_ORB_URL)} css={cardButton}>
          <Logo src={logoOrb} css={logoPadding} />
        </CardButton>
        <CardButton onClick={redirectOnClick(REACT_APP_PAY_URL)} css={cardButton}>
          <Logo src={logoPay} css={logoPadding} />
        </CardButton>
        <CardButton onClick={redirectOnClick(REACT_APP_XPERT_URL)} css={cardButton}>
          <Logo src={logoXpert} css={logoPadding} />
        </CardButton>
        <CardButton onClick={redirectOnClick(REACT_APP_BILLING_URL)} css={cardButton}>
          <Logo src={logoBilling} css={logoPadding} />
        </CardButton>
        <CardButton onClick={redirectOnClick(REACT_APP_MOOV_URL)} css={cardButton}>
          <Logo src={logoMoov} css={logoPadding} />
        </CardButton>
      </Column>
    </Row>
  )
}

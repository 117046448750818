export const onNumberChange = (min, max, emitChangeEvent) => {
  const lowBoundary = min && Number(min)
  const highBoundary = max && Number(max)
  return (event) => {
    const { value } = event.target
    if (lowBoundary <= value) {
      emitChangeEvent(lowBoundary)
    } if (value >= highBoundary) {
      emitChangeEvent(highBoundary)
    } else {
      emitChangeEvent(value)
    }
  }
}

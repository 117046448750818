/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React from 'react'
import { colors } from '../../assets/styles/colors'
import { useToast } from '../ToastNotification/utils/useToast'
import { PlusIcon, CloseIcon } from '../Icons/Icons'
import { InputFormGroup } from '../Input/InputFormGroup'
import { MaskedInput } from '../Input/Input'
import { normalizeString } from '@bonitour/common-functions'

const plusIcon = css`
  padding: 8px 10px;
  border-radius: 50%;
  background-color: ${colors.purple2};
  margin-right: 10px;
  color: white;
`

const keyWordsContainer = css`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
`

const inputGroupWithLimitStyles = css`
display: flex;
gap: 5px;

.keywords {
  top: 1193px;
  left: 90px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0px 3px 6px #00000029;
  color: ${colors.gray3};
  margin: 5px;
  padding: 5px 16px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font: normal normal normal 14px/18px Mulish;
}

.closeIcon {
  padding: 5px;
  padding-left: 0;
  cursor: pointer;
  margin-left: -2px;
}
`

export function KeyWords ({
  setNewKeyWord,
  newKeyWord,
  setKeyWords,
  label,
  keyWords
}) {
  const { add: addToast } = useToast()
  const [isVisibleKeyWordInput, setVisibleKeyWordInput] = React.useState(false)

  React.useEffect(() => {
    document.addEventListener('keydown', (e) => e.key === 'Escape' && setVisibleKeyWordInput(false))
  })

  const removeKeyWord = React.useCallback((value) => {
    setKeyWords(keyWords.filter(item => item !== value))
  }, [keyWords, setKeyWords])

  const addKeyWord = (value) => {
    if (value === '') {
      setNewKeyWord('')
      setVisibleKeyWordInput(v => !v)
    } else if (keyWords.includes(normalizeString(value))) {
      setNewKeyWord('')
      setVisibleKeyWordInput(v => !v)
      addToast('Esta palavra já está sendo usada')
    } else {
      setVisibleKeyWordInput(v => !v)
      setKeyWords((v) => v.concat(normalizeString(value).trim()))
      setNewKeyWord('')
    }
  }
  const keyWordsGroup = React.useMemo(() =>
    keyWords.map(value =>
      <span key={value} className='keywords'>
        <CloseIcon
          className='closeIcon'
          onClick={() => removeKeyWord(value)}
        />{value}
      </span>
    ), [removeKeyWord, keyWords])

  return (
    <InputFormGroup label={label} css={inputGroupWithLimitStyles}>
      <div css={keyWordsContainer}>
        {keyWordsGroup}
        {isVisibleKeyWordInput &&
          <MaskedInput
            className='keywords'
            value={newKeyWord}
            onChange={(v) => setNewKeyWord(v)}
            onBlur={addKeyWord}
            onKeyPress={(e) => e.charCode === 13 ? addKeyWord(newKeyWord) : e.charCode === 27 && setVisibleKeyWordInput(false)}
          />}
        <PlusIcon
          style={{ cursor: 'pointer' }}
          css={plusIcon}
          onClick={() => setVisibleKeyWordInput(v => !v)}
        />
      </div>
    </InputFormGroup>
  )
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { baseFont } from '../../assets/styles/styles'
import { flexColumnCentered } from '../../assets/styles/layout'
import { colors } from '../../assets/styles/colors'
import { normalizeMonth, normalizeDay, normalizeWeekday } from '../../utils/date'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { getLanguageCode } from '../../utils/getLanguageCode'

const dateIndicator = css`
  position: relative;
  width: 58px;
  height: 58px;
  border: 2px solid ${colors.secondary};
  border-radius: var(--round-element-radius, 100%);
  background-color: ${colors.white};

  ${baseFont}
`

const weekdayText = css`
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${colors.gray4};
`

const dayText = css`
  font-size: 25px;
  font-weight: 500;
  color: ${colors.gray1};
  line-height: 1;
`

const monthLabel = css`
  position: absolute;
  top: -12px;
  padding: 3px 8px;
  border-radius: var(--small-round-element-radius, 12px);
  background-color: ${colors.gray5};
  font-size: 12px;
  color: ${colors.white};
  box-shadow: 0px 0px 3px #00000040;
`

const disabledState = css`
  opacity: 0.5;
  border-color: ${colors.white};
`

const progressbar = css`
  position: absolute;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 0.5s;
`

const hidden = css`
  opacity: 0;
`

export function DateIndicator ({
  disabled,
  date = new Date(),
  progress = null,
  progressColor = colors.secondary,
  customCss = [],
  lang = 'pt_BR',
  ...other
}) {
  const safeLang = getLanguageCode(lang)

  const month = normalizeMonth(date, { lang: safeLang })
  const weekday = normalizeWeekday(date, { lang: safeLang })
  const day = normalizeDay(date)

  const isFirstDayOfMonth = date.getDate() === 1

  return (
    <div
      {...other}
      css={[dateIndicator, flexColumnCentered, disabled && disabledState, ...customCss]}
      style={{ border: progress === null || 'none' }}
    >
      <CircularProgressbar
        css={[progressbar, progress || hidden]}
        value={progress}
        strokeWidth={4}
        styles={buildStyles({
          pathColor: progressColor,
          strokeLinecap: 'round',
          pathTransitionDuration: 0.5
        })}
      />
      {isFirstDayOfMonth && <span css={monthLabel}>{month}</span>}
      <span css={weekdayText}>{weekday}</span>
      <span css={dayText}>{day}</span>
    </div>
  )
}

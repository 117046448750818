/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { colors } from '../../assets/styles/colors'
import { reset } from '../../assets/styles/input'

export const horizontalPadding = '20px'

export const disabled = css`
  background-color: ${colors.gray14};
  -webkit-text-fill-color: ${colors.gray6};
  color: ${colors.gray6};
`

// +1px font-size to fix input height
const baseStyle = css`
  width: 100%;
  height: 120px;
  margin: 0;
  padding: 10px ${horizontalPadding};
  font-size: 15px;
  font-family: inherit;
  color: ${colors.gray3};
  box-sizing: border-box;
  resize: none;
  border: 1px solid ${colors.gray8};
  border-radius: 15px;

  &::placeholder{
    color: ${colors.gray7};
  }

  &:read-only {
    ${disabled}
  }

  &:disabled {
    ${disabled}
  }
`

const errorInput = css`
  border-color: ${colors.danger};
`

export const Textarea = ({ onChange: emitChangeEvent = identity, error: hasError, ...props }) => {
  const onChange = event => emitChangeEvent(event.target.value)
  return <textarea {...props} css={[baseStyle, reset, hasError && errorInput]} onChange={onChange} />
}

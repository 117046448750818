import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'

import { Affiliate, AffiliateBatch, AffiliateBatchResponse, AffiliateSalesByCompanyProps, AffiliateSignup, AffiliatesTotalizersProps, AffiliateTicketsToBatch, AffiliateTicketsToBatchParamsListSearchParams, GetAffiliateResponse, ListAffiliateCompaniesProps, ListAffiliateSalesProps, ListAffiliateSalesResponse, ListAffiliatesProps, ListAffiliatesResponse, NonPaginatedAffiliate, ListPaginatedAffiliates, ListPaginatedAffiliatesResponse } from '@/types/domains/Affiliate'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { cleanPayload } from '@/utils/clean-payload'

const affiliateService = authenticatedService('v1')
const affiliateInviteService = authenticatedService('')

const affiliateRequestURL = 'affiliates'
const companyAffiliateRequestURL = 'companies'

type ticketsToBatchParams = { searchParams: AffiliateTicketsToBatchParamsListSearchParams; pagination: MetaOnRequest }

const paginatedList = (filters: ListPaginatedAffiliates): Promise<ListPaginatedAffiliatesResponse> => affiliateService.get(filters, affiliateRequestURL).then(extractData)

const create = (affiliate: Affiliate): Promise<Affiliate> =>
  affiliateService.post(affiliate, affiliateRequestURL).then(extractData)

const get = (affiliateId: string, companyId: string): Promise<GetAffiliateResponse> => {
  if (!companyId) {
    return affiliateService.get({}, `${affiliateRequestURL}/${affiliateId}`).then(extractData)
  }
  return affiliateService
    .get({}, `${companyAffiliateRequestURL}/${companyId}/${affiliateRequestURL}/${affiliateId}`)
    .then(extractData)
}

const createBatch = (affiliateBatch: AffiliateBatch, affiliateCode: string): Promise<AffiliateBatchResponse> =>
  affiliateService.post(affiliateBatch, `${affiliateRequestURL}/${affiliateCode}/batch`)

const update = (affiliateId: string, data: Affiliate): Promise<null> => {
  return affiliateService.patch(data, `${affiliateRequestURL}/${affiliateId}`).then(extractData)
}

const updateCustomCommission = (affiliateId: string, data: { percentage_by_service: Record<string, number> }): Promise<null> => {
  return affiliateService.patch(data, `${affiliateRequestURL}/${affiliateId}/custom_commission`).then(extractData)
}

const list = (data: ListAffiliatesProps): Promise<ListAffiliatesResponse> =>
  affiliateService.get(data, affiliateRequestURL).then(extractData)

const affiliateTicketsToBatch = ({ searchParams, pagination }: ticketsToBatchParams, affiliateCode: string): Promise<AffiliateTicketsToBatch> =>
  affiliateService.get({ ...cleanPayload(searchParams), ...pagination }, `${affiliateRequestURL}/${affiliateCode}/tickets_to_batch`).then(extractData)

const nonPaginatedList = (): Promise<NonPaginatedAffiliate[]> =>
  affiliateService.get({}, `${affiliateRequestURL}_non_paginated`).then(extractData)

const sales = (affiliateId: string, data: ListAffiliateSalesProps): Promise<ListAffiliateSalesResponse> =>
  affiliateService.get(data, `${affiliateRequestURL}/${affiliateId}/sales`).then(extractData)

const toggleActive = (affiliateId: string): Promise<null> =>
  affiliateService.patch({}, `${affiliateRequestURL}/${affiliateId}/toggle_active`).then(extractData)

const affiliateCompanies = (data: ListAffiliateCompaniesProps): Promise<null> =>
  affiliateService.get(data, '/affiliate_companies').then(extractData)

const getSalesByCompany = (affiliateId: string, params: AffiliateSalesByCompanyProps): Promise<null> =>
  affiliateService.get(params, `${affiliateRequestURL}/${affiliateId}/company_sales`).then(extractData)

const getTotalizersByCompany = (affiliateId: string, params: AffiliatesTotalizersProps): Promise<null> =>
  affiliateService.get(params, `${affiliateRequestURL}/${affiliateId}/totalizers`).then(extractData)

const getItemsToExport = (affiliateId: string, format: string, payload = {}): Promise<any> =>
  affiliateService.get(
    { ...cleanPayload(payload) },
    `${affiliateRequestURL}/${affiliateId}/export_sales/${format}`,
    { responseType: format === 'xlsx' ? 'arraybuffer' : undefined }
  ).then(extractData)

const getItemsToExportByCompany = (companyId: string, format: string, payload = {}): Promise<any> =>
  affiliateService.get(
    { ...cleanPayload(payload) },
    `${affiliateRequestURL}/${companyId}/export_company_sales/${format}`,
    { responseType: format === 'xlsx' ? 'arraybuffer' : undefined }
  ).then(extractData)

const userInviteSignup = (affiliate: AffiliateSignup): Promise<Affiliate> =>
  affiliateInviteService.post(affiliate, 'affiliates_user_invite_signup')

affiliateService.setServiceMethods({
  create,
  createBatch,
  update,
  updateCustomCommission,
  list,
  nonPaginatedList,
  sales,
  toggleActive,
  get,
  affiliateTicketsToBatch,
  affiliateCompanies,
  getSalesByCompany,
  getTotalizersByCompany,
  getItemsToExport,
  getItemsToExportByCompany,
  paginatedList
})
affiliateInviteService.setServiceMethods({ userInviteSignup })

export const affiliateDomain = affiliateService.initialize
export const affiliateInviteDomain = affiliateInviteService.initialize

import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import {
  BankStatementsImportParams,
  BankStatementsImportRes,
  BankStatementsListParams,
  BankStatementsListRes,
  BankStatementsDeleteParams,
  BankStatementsDeleteRes,
  BankStatementsImportedParams,
  BankStatementsImportedRes
} from '@/types/domains/BankStatements'

const bankStatementsService = authenticatedService('v1/bank_statements')

const bankStatementsImport = async (
  searchParams: BankStatementsImportParams
): Promise<BankStatementsImportRes> => {
  return await bankStatementsService.postFormData(
    searchParams,
    'import'
  ).then(extractData)
}

const list = async (
  { searchParams, pagination }: BankStatementsListParams
): Promise<BankStatementsListRes> => {
  return await bankStatementsService.get({
    ...searchParams,
    ...pagination
  }).then(extractData)
}

const remove = async (
  params: BankStatementsDeleteParams
): Promise<BankStatementsDeleteRes> => {
  return await bankStatementsService.remove(
    params
  ).then(extractData)
}

const getImported = (params: BankStatementsImportedParams): Promise<BankStatementsImportedRes> => {
  return bankStatementsService.get(params, 'imported').then(extractData)
}

bankStatementsService.setServiceMethods(
  {
    bankStatementsImport,
    list,
    remove,
    getImported
  }
)

export const bankStatementsDomain = bankStatementsService.initialize

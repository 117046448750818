import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { ExceptionalDay, ExceptionalDayRes } from '@/types/domains/ExceptionalDay'

const exceptionalDayService = authenticatedService('v1')

const create = (exceptionalDay: ExceptionalDay): Promise<ExceptionalDayRes | any> => {
  return exceptionalDayService.post(exceptionalDay, 'exceptional_day').then(extractData)
}

const getById = (exceptionalDayId: string): Promise<ExceptionalDayRes | any> => {
  return exceptionalDayService.get({}, `exceptional_days/${exceptionalDayId}`).then(extractData)
}

const list = (params: any): Promise<any> => {
  return exceptionalDayService.get({ ...params }, 'exceptional_days').then(extractData)
}

const update = (exceptionalDay: ExceptionalDay, exceptionalDayId: string): Promise<ExceptionalDayRes | any> => {
  return exceptionalDayService.patch(exceptionalDay, `exceptional_days/${exceptionalDayId}`)
}

const remove = (exceptionalDayId: string): Promise<any> => exceptionalDayService.remove({}, `exceptional_days/${exceptionalDayId}`)

exceptionalDayService.setServiceMethods({ create, getById, list, update, remove })

export const exceptionalDayDomain = exceptionalDayService.initialize

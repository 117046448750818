import { authenticatedService } from '@/interfaces/authenticatedService'
import { ISOTime } from '@/types/domains/Abstractions'
import { extractData } from '@/utils/data-extractor'

const serviceService = authenticatedService('/v1/services')

function get () {
  return serviceService.get()
}

function getById (serviceId: string) {
  return serviceService.get({}, serviceId)
}

function getDailyMonitoringReservations (serviceId: string, date: ISOTime) {
  return serviceService.get({ date }, `${serviceId}/daily_monitoring_reservations`).then(extractData)
}

function getAdditionalInformation () {
  return serviceService.get({}, '/additional_information').then(extractData)
}

serviceService.setServiceMethods({ get, getById, getDailyMonitoringReservations, getAdditionalInformation })

export const serviceDomain = serviceService.initialize

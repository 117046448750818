/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import {
  dangerTheme,
  successTheme,
  infoTheme,
  infoLightTheme,
  warningTheme,
  warningLightTheme
} from '../../assets/styles/container'
import { CloseIcon, CheckIcon, InfoIcon, WarningIcon } from '../../components/Icons/Icons'
import { CircleThumbnail } from '../../components/Thumbnails/Thumbnails'
import { baseFont } from '../../assets/styles/styles'
import { colors } from '../../assets/styles/colors'
import { flexHorizontallyCentered, flexRowCentered } from '../../assets/styles/layout'

const alert = css`
  margin: 5px;
  padding: 13px;
  border-radius: 10px;
  font-size: 15px;
  position: relative;
  overflow: hidden;
`

const iconDefault = css`
  background-color: ${colors.white};
  color: ${colors.white};
  margin-right: 10px;
  min-width: 35px;
  font-size: 15px;
`

const iconWarning = css`
  background-color: ${colors.yellow2};
`
const iconSuccess = css`
  background-color: ${colors.green2};
`
const iconDanger = css`
  background-color: ${colors.red3};
`
const iconInfo = css`
  background-color: ${colors.gray4};
`
const iconInfoLight = css`
  background-color: ${colors.gray5};
  color: ${colors.white};
`
const iconLightWarning = css`
  background-color: ${colors.warning};
  color: ${colors.white};
`

export const Alert = props => <div css={[alert, baseFont, flexHorizontallyCentered]} {...props} />

export const DangerAlert = ({ children, ...other }) => (
  <Alert css={dangerTheme} {...other}>
    <CircleThumbnail size={35} css={[flexRowCentered, iconDefault, iconDanger]}>
      <CloseIcon />
    </CircleThumbnail>
    {children}
  </Alert>
)

export const SuccessAlert = ({ children, ...other }) => (
  <Alert css={successTheme} {...other}>
    <CircleThumbnail size={35} css={[flexRowCentered, iconDefault, iconSuccess]}>
      <CheckIcon />
    </CircleThumbnail>
    {children}
  </Alert>
)

export const InfoAlert = ({ children, ...other }) => (
  <Alert css={infoTheme} {...other}>
    <CircleThumbnail size={35} css={[flexRowCentered, iconDefault, iconInfo]}>
      <InfoIcon />
    </CircleThumbnail>
    {children}
  </Alert>
)

export const LightInfoAlert = ({ children, ...other }) => (
  <Alert css={infoLightTheme} {...other}>
    <CircleThumbnail size={35} css={[flexRowCentered, iconDefault, iconInfoLight]}>
      <WarningIcon />
    </CircleThumbnail>
    {children}
  </Alert>
)

export const WarningAlert = ({ children, customCss = [], ...other }) => (
  <Alert css={[warningTheme, ...customCss]} {...other}>
    <CircleThumbnail size={35} css={[iconDefault, flexRowCentered, iconWarning]}>
      <WarningIcon />
    </CircleThumbnail>
    {children}
  </Alert>
)

export const LightWarningAlert = ({ children, ...other }) => (
  <Alert css={warningLightTheme} {...other}>
    <CircleThumbnail size={35} css={[iconDefault, flexRowCentered, iconLightWarning]}>
      <WarningIcon />
    </CircleThumbnail>
    {children}
  </Alert>
)
